/** @jsx jsx */
import { jsx } from "theme-ui";

import { Layout, Content, Section } from "maker-ui";

import React, { useCallback, useEffect } from "react";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";
import Seo from "../components/seo";
import { eventCallbackTriggers } from "../templates/wppage";

require("react-datepicker/dist/react-datepicker.css");
require("bootstrap/dist/css/bootstrap.min.css");

const pageContent =
  '<gatsby_donation amount="0" donationid="33" btntext="Contribute Now"' +
  'projectid="" btnbg="#333333" colortext="black" colorerrortext="#D82727" colorprimary="#333333"' +
  'colorsecondary="#333333" colorerrorbg = "white" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Every Drop Counts - Donation, Recurring" isrecurring="true" enablesigninoption="0"/>';
const PageContent = React.memo(PageContentNonMemoized);

const EveryDropCountsHiMobile = () => {
  const [, setIsMobileSite] = usePersist("isMobileSite", false);

  useEffect(() => {
    setIsMobileSite(true);
  }, [setIsMobileSite]);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("every-drop-counts", event),
    []
  );

  return (
    <Layout theme={theme} options={options}>
      <Seo title="Heartfulness Donations - Every Drop Counts - Mobile" />
      <Content>
        <Section>
          <div
            className="container"
            sx={{
              color: "#2b2b2b",
              fontSize: "18px",
              fontFamily:
                '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji',
            }}
          >
            <div className="col-12">
              <div sx={{ textAlign: "center", mt: ["2rem", "2rem", "5rem"] }}>
                <p
                  sx={{
                    fontSize: ["30px", "30px", "75px"],
                    fontWeight: "bolder",
                    mb: 0,
                  }}
                >
                  Every Drop Counts
                </p>
                <p
                  sx={{
                    mb: 4,
                    fontSize: ["15px", "15px", "35px"],
                    color: "#2E9819",
                    fontFamily: "home-made-apple",
                  }}
                >
                  a golden opportunity
                </p>
              </div>

              <p className="mb-4">
                Every day, more and more people around the world are
                experiencing the Heartfulness practices and the incredible
                benefits they bring. This momentum is due to the efforts of our
                volunteers, in giving both their time and resources to sharing
                this beautiful and simple system with others.
              </p>
              <p className="mb-4">
                Kanha Shanti Vanam has emerged as a world-renowned spiritual
                wellness center, and our outreach programs in schools, colleges,
                universities, corporates, governments, villages and
                neighborhoods have helped both children and adults to manage
                stress, feel inner peace, learn to regulate their minds and
                emotions, and live purposeful and fulfilling lives.
              </p>
              <p className="mb-4">
                The number of individuals and organizations requesting our
                services is continually growing. In addition, when Kanha Shanti
                Vanam reopens, there will be an influx of more and more visitors
                who wish to rejuvenate and revitalize themselves. We will also
                restart all of our activities with renewed enthusiasm, e.g.:
              </p>
              <div
                sx={{
                  ml: [3, 4, 5],
                  mb: 4,
                }}
              >
                <div sx={{ marginBottom: "10px" }}>
                  - Education – this has been our most successful offering,
                  reaching millions of school, college and university students,
                </div>
                <div sx={{ marginBottom: "10px" }}>
                  - Government – various services at the municipal, state and
                  national level, including training Asha, Anganwadi workers and
                  Sarpanches at the village level,
                </div>
                <div sx={{ marginBottom: "10px" }}>- A Covid Relief Fund,</div>
                <div sx={{ marginBottom: "10px" }}>
                  - The `Voice That Cares` helpline,
                </div>
                <div sx={{ marginBottom: "10px" }}>
                  - Medical programs in many ashrams,
                </div>
              </div>
              <p className="mt-4 mb-4">
                Imagine if we all participate in these efforts, no matter how
                small the amount! Daaji`s call for us to contribute INR 50 or $1
                a day for these noble causes is still current and would make a
                big difference.
              </p>
              <p className="mb-4">
                This is a golden opportunity to be part of the Heartfulness
                Movement for humanity. Please donate monthly so that we are able
                fund these programs. Every drop counts. Together we can make a
                difference, so that many more people will benefit from our
                voluntary services around the world.
              </p>
            </div>
          </div>
          <div className="container">
            <div
              id="form"
              sx={{
                mt: ["6em", "9em", "6em"],
                fontSize: ["16px", "16px", "28px"],
                fontWeight: "bolder",
                textAlign: "center",
                color: "#798d2c",
              }}
            >
              Come, join the making of this ocean of Heartfulness!
              <br />
              Contribute, as every drop counts!
            </div>
            <div className="col-12 text-center my-4 py-1">
              <PageContent
                eventCallback={eventCallback}
                pageContent={pageContent}
              />
            </div>
            <p sx={{ fontSize: "15px", textAlign: "center", color: "#63767a" }}>
              You may email to us at{" "}
              <a href="mailto:info.accounts@sahajmarg.org">
                info.accounts@sahajmarg.org
              </a>{" "}
              or missed call (India) at +91-89395-89295 or Call toll free
              (india) at 1800-121-3492
            </p>
            <p className="mb-4 pb-4">&nbsp;</p>
          </div>
        </Section>
      </Content>
    </Layout>
  );
};

export default EveryDropCountsHiMobile;
